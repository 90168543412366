import { render, staticRenderFns } from "./IssueSummary.vue?vue&type=template&id=9ad8edae&scoped=true"
import script from "./IssueSummary.vue?vue&type=script&lang=js"
export * from "./IssueSummary.vue?vue&type=script&lang=js"
import style0 from "./IssueSummary.vue?vue&type=style&index=0&id=9ad8edae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ad8edae",
  null
  
)

export default component.exports